<template>
<div>

  <div class="container-fluid wrapper-medium content-form">
    <div class="row">

      <div class="col-12 col-md-6" v-if="form.inviteCode !== null && form.inviteEmail !== null">
        <h1 class="mb-3 anim-load1">
          You have been invited to join TAYC&#x2011;R
        </h1>
        <p class="lead anim-load2">
           Please follow these steps to create your therapist account
          <br>Have a question? <b-link class="btn-link" to="/faq" target="_blank">See our FAQs</b-link>
        </p>
      </div>

      <div class="col-12 col-md-6">
        <transition-group name="block-item" tag="div">
          <div class="card mt-3 mt-md-0" key="1" v-if="!submitSuccess">
            <div class="card-body">
              <ValidationObserver ref="form">
                <b-form @submit.prevent="onSubmit" class="mb-3">

                  <div class="form-group" ref="email">
                    <label for="emailInput">Email address</label>
                    <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address">
                      <input v-model="form.email" type="text" placeholder="" name="emailInput" class="form-control" v-bind:class="classes" disabled>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="form-group mt-3" ref="password">
                    <label for="password">Create password</label>
                    <ValidationProvider v-slot="{classes, errors}" rules="required|max:100" name="password">
                      <input v-model="form.password" type="password" placeholder="" name="password" class="form-control" v-bind:class="[classes, {'force-invalid-border': !form.passwordValidation.valid}]">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div>
                    <p class="mb-2">Your password must:</p>
                    <ul class="list-requirements">
                      <li v-bind:class="{'done': form.passwordValidation.charLength === true }">be a minimum of 8 characters</li>
                      <li v-bind:class="{'done': form.passwordValidation.uppercase === true }">contain uppercase characters</li>
                      <li v-bind:class="{'done': form.passwordValidation.lowercase === true }">contain lowercase characters</li>
                      <li v-bind:class="{'done': form.passwordValidation.digit === true }">contain at least one digit</li>
                      <li v-bind:class="{'done': form.passwordValidation.special === true }">contain at least one special character</li>
                    </ul>
                  </div>

                  <div class="form-group mt-3 pb-3 border-bottom" ref="password">
                    <label for="email">Confirm password</label>
                    <ValidationProvider v-slot="{classes, errors}" rules="required|password:@password" name="passwordConfirm">
                      <input v-model="form.passwordConfirm" type="password" placeholder="" name="passwordConfirm" class="form-control" v-bind:class="classes">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="form-group mt-3" ref="terms">
                    <ValidationProvider v-slot="{classes, errors}" :rules="{ required: { allowFalse: false } }" name="Terms and Conditions">
                      <div class="custom-control custom-checkbox custom-control-large" v-bind:class="classes">
                        <input type="checkbox" class="custom-control-input" id="terms" :value="true" v-model="form.terms">
                        <label class="custom-control-label" for="terms"><p class="mb-0">I agree to the <b-link target="_blank" to="/privacy-policy" class="link">Privacy Policy</b-link> and <b-link target="_blank" to="/terms-of-use" class="link">Terms of use</b-link></p></label>
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <b-alert variant="danger"
                    dismissible
                    :show="form.passwordValidation.errorDisplay"
                    @dismissed="form.passwordValidation.errorDisplay=false">
                    <p class="mb-0">Your password doesn't meet all requirements listed above.</p>
                  </b-alert>

                  <b-alert variant="danger"
                    dismissible
                    :show="showDismissibleAlert"
                    @dismissed="showDismissibleAlert=false">
                    {{serverError}}
                  </b-alert>

                  <button type="submit" class="btn btn-warning btn-lg btn-block">Sign up</button>
                </b-form>
              </ValidationObserver>        
            </div>
          </div>

          <div class="card mt-3 mt-md-0" key="2" v-if="submitSuccess">
            <div class="card-body text-center">
              <figure class="img-gesture-round mb-2 ml-auto mr-auto">
                <img :src="$randomGestureImage('email', 2)" alt="">
              </figure>
              <h2 class="mb-3">Verify your email</h2>
              <p class="lead">Thank you for signing up to TAYC-R. Please visit your inbox to verify your email.</p>
              <p class="pt-4">If you don't see a verificaiton email shortly, please check your junk email folder just in case it got delivered there instead.</p>
            </div>
          </div>
        </transition-group>

      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>


export default {
  name: 'Signup',
  data () {
    return {
      form: {
        email: '',
        password: '',
        passwordConfirm: '',
        passwordValidation: {
          charLength: false,
          uppercase: false,
          lowercase: false,
          digit: false,
          special: false,
          errorDisplay: false,
          valid: false
        },
        inviteEmail: null,
        inviteCode: null
      },
      serverError: null,
      showDismissibleAlert: false,
      submitSuccess: false
    }
  },
  computed: {
    formPassword() {
      return this.form.password
    },
  },
  async created() {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
    localStorage.removeItem('jwt')
    this.$store.state.jwt = null
    this.form.inviteCode = this.$route.query.code || null
    this.form.inviteEmail = this.$route.query.email || null
    if (this.form.inviteCode !== null && this.form.inviteEmail !== null) {
      this.form.inviteEmail = this.form.inviteEmail.replace(/\s/g, "+")
      this.form.inviteEmail = JSON.parse(JSON.stringify(this.form.inviteEmail))
      console.log('invite found')
      await this.validateInvite();
    } else {
      // Block access to this page if code/email not present
      this.$router.push('/')
    }
  },

  methods: {
    validateInvite() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'post', 
        url: this.$api.TherapistValidateInvite,
        params: {
          email: this.form.inviteEmail,
          code: this.form.inviteCode
        }
      })
        .then((response) => {
          console.log(response)
          if (response.status !== 200) {
            this.alertMsgShow("Invite expired.", false)
            this.$router.push('/')
          } else {
            this.form.email = this.form.inviteEmail
          }
        })
        .catch((error) => {
          console.log(error)
          this.alertMsgShow("Invite expired", false)
          this.$router.push('/')
        })
        .finally(() => {
          this.$store.state.loadingProgress = false
        })
    },

    onSubmit() {
      console.log('onSubmit')

      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          if (this.form.passwordValidation.uppercase && this.form.passwordValidation.lowercase && this.form.passwordValidation.digit && this.form.passwordValidation.special && this.form.passwordValidation.charLength) {
            this.form.passwordValidation.errorDisplay = false
            this.$store.state.loadingProgress = true
            var data = {
              email: this.form.email,
              password: this.form.password,
              confirmPassword: this.form.password,
              inviteEmail: this.form.inviteEmail,
              inviteCode: this.form.inviteCode
            }
            this.$http({
              method: 'post', 
              url: this.$api.AccountRegister,
              data: data
            })
            .then((response) => {
              console.log(response)
              if (response.status === 201) {
                this.submitSuccess = true
              } else {
                console.error('Error', response)
                if (response.data.message !== undefined) {
                  this.showError(response.data.message)
                } else {
                  this.showError('Something went wrong. Please try again later.')
                }
              }
            })
            .catch((error) => {
              console.error('Error', error)
              this.showError('Something went wrong')
            })
            .finally(() => {
              this.$store.state.loadingProgress = false
            })

          } else {
            this.form.passwordValidation.errorDisplay = true
          }
        }
      });
    },

    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    }
  },

  watch: {
    formPassword () {
      this.form.passwordValidation = this.passwordValidate(this.form.password)
    }
  }
}
</script>
